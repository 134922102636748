.input-message-container {
  margin-top: 0.3rem;

  .invalid,
  .valid {
    font-size: 1rem;
  }

  .invalid {
    color: $danger;
  }

  .valid {
    color: $success;
  }
}
